const firebaseConfig = {
  apiKey: "AIzaSyD50IPLkAjj30Xm23UFhjCQxdoUXv7LiGI",
  authDomain: "ethdenver-2020-test.firebaseapp.com",
  databaseURL: "https://ethdenver-2020-test.firebaseio.com",
  projectId: "ethdenver-2020-test",
  storageBucket: "ethdenver-2020-test.appspot.com",
  messagingSenderId: "974071877533",
  appId: "1:974071877533:web:1adb91aa37d229ab9e2974",
  measurementId: "G-EY1R458ZDM"
};

module.exports = { firebaseConfig: firebaseConfig }