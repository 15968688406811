import * as config from './config'

// setPerson : Updates Rollbar object to allow error people tracking
//    rollbar   : main Rollbar object
//    person    : firebase.auth().currentUser
const setPerson = (rollbar, personUID, personEmail) => {
  rollbar.configure({
    payload: {
      person: {
        id: personUID, // required
        email: personEmail,
      }
    }
  });
}

// setVersion : Updates Rollbar object to allow tracking of version/git-hash and branch
//    rollbar   : main Rollbar object
const setVersion = (rollbar) => {
  rollbar.configure({
    payload: {
      client: {
        javascript : {
          code_version : config.commitHash,
        }
      },
      server: {
        branch: config.gitBranch
      },
    }
  });
}

module.exports = {
  setPerson: setPerson,
  setVersion: setVersion,
}