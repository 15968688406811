import { firebaseConfig } from './config/staging.js';

module.exports = {
  stakingContractAddress: "0xd7f35d719997360c30e61d16e3cc4efd23cd0420",
  grantSignerAddress: "0xFC76E886Cb44FeDf3c61Cc5AB4d836E06154F494",
  fortmaticAPIKey: "pk_test_C90E083F46BF5FB2",
  rollbarEnvironment: "staging",
  ethNetwork: 4,
  firebaseConfig: firebaseConfig,
  commitHash: "master",
  gitBranch: "1c7758f27e0ed43daaddbb2f8b056885e565c1d9",
}
