/*
TODO:

[x] Get accounts from fortmatic.
[x] Sign message and validate with server.
[x] Stake money into contract. Require server sign off.
[ ] Email upon success.
[x] Allow early withdrawal. Require server sign off.

*/

// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import * as ethUtil from 'ethereumjs-util';
import Fortmatic from 'fortmatic';
import Web3 from 'web3';

import { Elm } from './elm/Main.elm';
import contractABI from './ethereum/contractABI.json';
import * as config from './config'
import * as rollbarUtils from './rollbar-utils'

rollbarUtils.setVersion(Rollbar);

// Initialize Firebase
firebase.initializeApp(config.firebaseConfig);
firebase.analytics();
let firestore = firebase.firestore();
firestore.settings({});

// Enable local caching
firebase.firestore().enablePersistence({synchronizeTabs:true});

// Set up Fortmatic and Web3
window.fm = new Fortmatic(config.fortmaticAPIKey);
window.fmWeb3 = new Web3(fm.getProvider());
let userAddress;

// Set up Elm

let elmApp = Elm.Main.init({ flags: typeof window.web3 !== "undefined" });

fmWeb3.eth.getAccounts(function(error, accounts) {
  if (!modalClosed(error, "getAccounts")) {
    userAddress = accounts[0];
    fm.user.getUser()
      .then(function(fmUser) {
        if (error) { console.log("User closed Fortmatic modal", error); return;};
        let fortmaticAccount = {
          address: userAddress,
          mEmail: fmUser.email,
          fortmaticId: fmUser.userId
        }
        elmApp.ports.setFortmaticAccount.send(fortmaticAccount);
        rollbarUtils.setPerson(Rollbar, fmUser.userId, fmUser.email);
      })
      .catch(function(err) {
        modalClosed(err, "getAccounts")
      })
  }
})


elmApp.ports.signMsg.subscribe(function(data) {
  const msg = ethUtil.bufferToHex(new Buffer('ethdenver2020', 'utf8'));
  const params = [msg, data.userAccount];
  const method = 'personal_sign';
  fmWeb3.currentProvider.sendAsync(
    { id: 1, method, params, from: data.userAccount },
    function(error, result) {
      if (!modalClosed(error, "personal_sign")) {
        elmApp.ports.setSignedMsg.send({
          signedMsg: result.result,
          fortmaticAccount: data.userAccount,
          fortmaticId: data.fortmaticId,
        });
      }
    }
  );
});


elmApp.ports.firebaseAuth.subscribe(function(customToken) {
  if (!firebase.auth().currentUser) {
    firebase.auth().signInWithCustomToken(customToken)
    .then(function() {
      fm.user.getUser().then(function(user) {
        user.fortmaticEthAddress = userAddress;
        firestore
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .set(user, { merge: true })
          .catch(logError);
      });
    })
    .catch(logError);
  }
});


elmApp.ports.logoutFortmatic.subscribe(function() {
  firebase.auth().signOut();
  fm.user.logout().then(function() {
    document.location.reload();
  });
});

function setWalletIfCorrectNetwork(account) {
  window.web3.eth.net.getId(function (error, version) {
    console.log("network info", error, version)
    if (error) { logError(error) };
    if (version === config.ethNetwork) {
      elmApp.ports.setWalletAddress.send(account);
    } else {
      elmApp.ports.setError.send(
        "Wrong Ethereum Network/Chain" +
        "\n\nPlease use Ethereum Network " +
        config.ethNetwork +
        "\n\n(Mainnet or Rinkeby)"
      );
    }
  })
}

elmApp.ports.setWallet.subscribe(function(walletChoice) {
  if (walletChoice === "fortmatic") {
    console.log("Fortmatic chosen")
    window.web3 = fmWeb3;
    window.web3.eth.getAccounts(function(error, accounts) {
      if (error) { logError(error) };
      setWalletIfCorrectNetwork(accounts[0]);
    });
  } else {
    if (window.ethereum) {
      console.log("Using modern browser wallet");
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable().then(function(accounts) {
        setWalletIfCorrectNetwork(accounts[0]);
      }).catch(logError);
    } else if (window.web3) {
      console.log("Using older browser wallet");
      window.web3 = new Web3(window.web3.currentProvider);
      window.web3.eth.getAccounts(function(error, accounts) {
        if (error) { logError(error) };
        setWalletIfCorrectNetwork(accounts[0]);
      });
    } else {
      console.warn("No browser wallet detected, defaulting to Fortmatic")
      window.web3 = fmWeb3;
      window.web3.eth.getAccounts(function(error, accounts) {
        if (error) { logError(error) };
        setWalletIfCorrectNetwork(accounts[0]);
      });
    }
  }
})


elmApp.ports.initStakeTx.subscribe(function(txParams) {
  const { fortmaticId, fortmaticAddress, walletAddress, expiryDate, grant, stakeAmount, usdAmount } = txParams;

  // helper function
  let setTxData = function(hash, data) {
    data.ethAddress = walletAddress;
    data.fortmaticAddress = fortmaticAddress;
    firestore
      .collection('users')
      .doc(fortmaticId)
      .collection('stakeTxs')
      .doc(hash)
      .set(data, { merge: true })
      .catch(logError);
  }

  let contract = new web3.eth.Contract(contractABI, config.stakingContractAddress);
  contract.methods.stake(fortmaticAddress, expiryDate, grant).send({
    value: stakeAmount,
    from: walletAddress
  })
  .once('transactionHash', function(hash){
    console.log(hash);
    setTxData(hash, { mined: false, when: Date.now(), usdAmount: usdAmount, weiAmount: stakeAmount });
    elmApp.ports.txData.send({
      tipe: "stake",
      txId: hash,
      error : null
    })
  })
  .once('receipt', function(receipt){
      console.log(receipt);
      setTxData(receipt.transactionHash, { mined: true, usdAmount: usdAmount, weiAmount: stakeAmount });

      firestore
        .collection('users')
        .doc(fortmaticId)
        .set({hasStaked: true, usdAmount: usdAmount, weiAmount: stakeAmount }, { merge: true })
        .catch(logError)

      elmApp.ports.txData.send({
        tipe: "stake",
        txId: txReceipt.transactionHash,
        error : null
      })
  })
  .on('error', function(error, txReceipt) {
    console.log(error, txReceipt);
    if (!modalClosed(error, "stake") && txReceipt) {
      setTxData(txReceipt.transactionHash, { mined: false });
      elmApp.ports.txData.send({
        tipe: "stake",
        txId: txReceipt.transactionHash,
        error: "Failed to send/mine stake tx"
      })
    } else {
      elmApp.ports.txData.send({
        tipe: "stake",
        txId: null,
        error: "Transaction Failure"
      })
    }
  })
});


elmApp.ports.initRecoupTx.subscribe(function(txParams) {
  const { fortmaticId, fortmaticAddress, walletAddress, expiryDate, grant, stakeAmount, usdAmount } = txParams;

  // helper function
  let setTxData = function(hash, data) {
    data.ethAddress = walletAddress;
    data.fortmaticAddress = fortmaticAddress;
    firestore
      .collection('users')
      .doc(fortmaticId)
      .collection('recoupTxs')
      .doc(hash)
      .set(data, { merge: true })
      .catch(logError);
  }

  let contract = new web3.eth.Contract(contractABI, config.stakingContractAddress);
  contract.methods.recoupStake(fortmaticAddress, expiryDate, grant).send({
    from: walletAddress
  })
  .once('transactionHash', function(hash){
    console.log(hash);
    setTxData(hash, { mined: false, when: Date.now() });
    elmApp.ports.txData.send({
      tipe: "recoup",
      txId: hash,
      error : null
    })
  })
  .once('receipt', function(receipt){
      console.log(receipt);
      setTxData(receipt.transactionHash, { mined: true });

      firestore
        .collection('users')
        .doc(fortmaticId)
        .set({hasRecouped: true, hasStaked: false }, { merge: true })
        .catch(logError)

      elmApp.ports.txData.send({
        tipe: "recoup",
        txId: txReceipt.transactionHash,
        error : null
      })
  })
  .on('error', function(error, txReceipt) {
    console.log(error, txReceipt);
    if (!modalClosed(error, "recoup") && txReceipt) {
      setTxData(txReceipt.transactionHash, { mined: false });
      elmApp.ports.txData.send({
        tipe: "recoup",
        txId: txReceipt.transactionHash,
        error: "Failed to send/mine recoup tx"
      })
    } else {
      elmApp.ports.txData.send({
        tipe: "recoup",
        txId: null,
        error: "Transaction Failure"
      })
    }
  })
});


// Helpers

function logError(error) {
  console.log(error);
  Rollbar.error(error);
}

window.logError = logError;

function modalClosed(error, tipe) {
  if (error === null) {return false};
  if (error.message.includes("Fortmatic: User denied")) {
    elmApp.ports.userClosedFortmaticModal.send(tipe);
    return true;
  } else {
    return false;
  }
}